<template>
  <div id="app">
    <nav-bar />
    <div class="col-xs-12" style=" margin: auto; margin-bottom: 100px; padding-left: 0px;">
      <router-view />
    </div>
    <Tour v-if="userDetails.visitCount < 3" ref="tourRef" :steps="steps" />
    <Loudspeaker
      title="Data Discovery News"
      :channelIds="['data-discovery']"
      :environment="environment"
      :accessToken="platformClient.tokenResolverFunction()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Loudspeaker, LoudspeakerEnvironment } from '@cimpress-technology/react-loudspeaker';
import NavBar from '@/components/NavBar';
import Tour from '@/components/Tour';
import TourData from '@/DataFiles/TourData.json';

export default {
  name: 'App',
  inject: ['platformClient'],
  components: {
    Loudspeaker,
    NavBar,
    Tour
  },
  data() {
    return {
      steps: TourData,
      userLoginDetails: ''
    };
  },
  computed: {
    ...mapState({
      userDetails: state => state.customizr.userDetails
    }),
    environment() {
      return LoudspeakerEnvironment.Production;
    }
  },
  async created() {
    try {
      await this.getUserDetails({ platformClient: this.platformClient });
      await this.updateUserVisitCount();
      await this.putUserDetails({ platformClient: this.platformClient, userDetailsObject: this.userDetails });
      if (this.userDetails.visitCount < 3 && this.$route.path === '/') {
        this.$refs.tourRef.callOnCreate(this.steps[0].target);
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions({
      getUserDetails: 'customizr/getUserDetails',
      putUserDetails: 'customizr/putUserDetails',
      updateUserVisitCount: 'customizr/updateUserVisitCount'
    })
  }
};
</script>
