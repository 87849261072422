<template>
  <!-- TODO: make a decision on using vue/attribute-hyphenation or not and update this if necessary  -->
  <cimba-header :appLinks="appLinks"
                :appId="appId"
                :appTitle="appTitle"
                :documentationUrl="documentationUrl"
                :mailToAddress="mailToAddress"
                :onLogOutClicked="logoutAction"
                :profile="profile"
  />
</template>

<script>
import CimbaHeader from '@/components/CimbaHeader';

export default {
  name: 'NavBar',

  inject: ['userManager', 'authManager', 'platformClient'],

  components: {
    CimbaHeader
  },

  data() {
    return {
      appId: 'data-discovery',
      appLinks: [
        { title: 'Home', href: '#/' },
        { title: 'My Bookmarks', href: '#/myBookmarks' },
        { title: 'My Datasets', href: '#/myTables' },
        { title: 'About Us', href: '#/about' }
      ],
      appTitle: 'Data Discovery',
      documentationUrl: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/518750596/Data+Discovery',
      mailToAddress: 'PandaSquad@cimpress.com'
    };
  },

  computed: {
    profile() {
      return this.userManager.getUserData();
    }
  },
  methods: {
    logoutAction() {
      this.authManager.logout();
    }
  }
};
</script>
