<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <Header :appLinks="links"
          :appTitle="title"
          :appId="appId"
          :documentationUrl="documentationUrl"
          :mailToAddress="mailToAddress"
          :isLoggedIn="true"
          :onLogInClicked="() => {}"
          :onLogOutClicked="onLogOutClicked"
          :profile="profile"
          :responsive="true"
  />
  <!-- eslint-enable vue/attribute-hyphenation -->
</template>

<script>
import Header from '@cimpress-technology/react-platform-header';
import React from 'react';

export default {
  name: 'CimbaHeader',

  components: {
    Header
  },

  props: {
    appId: {
      type: String,
      default: 'cimba'
    },
    appLinks: {
      type: Array,
      default: () => []
    },
    appTitle: {
      type: [String, Object],
      required: true
    },
    documentationUrl: {
      type: String,
      default: undefined
    },
    mailToAddress: {
      type: String,
      default: 'PandaSquad@cimpress.com'
    },
    onLogOutClicked: {
      type: Function,
      required: true
    },
    profile: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      links: []
    };
  },

  computed: {
    title() {
      return React.createElement(
        'a',
        {
          onClick: this.resetSearch,
          style: { textDecoration: 'none', cursor: 'pointer' }
        },
        this.appTitle
      );
    }
  },

  async mounted() {
    await this.initLinks();
  },

  methods: {
    initLinks() {
      const propLinks = (this.appLinks || []).map(link => ({
        id: link.href,
        content: link.title === 'Home'
          ? React.createElement('a', { onClick: this.resetSearch, style: { cursor: 'pointer' } }, link.title)
          : React.createElement('a', { href: link.href }, link.title)
      }));

      this.links = propLinks;
    },
    resetSearch() {
      this.$store.commit('search/searchInput', {
        searchText: null,
        filters: {}
      });
      this.$store.commit('search/selectedTreeItem', null);
      this.$store.commit('search/searchResult', null);
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    }
  }
};
</script>
