
// initial state
const initialState = {
  editHistory: {}
};

// Constants
const applicationName = 'data_discovery';

// actions
const actions = {
  async getPrincipalActions({ commit }, { platformClient, principal }) {
    commit('editHistory', '');
    let response = await platformClient.get(`https://trails.platform.cimpress.io/${applicationName}/v1/actions?principal=${principal}`);
    commit('editHistory', response.data);
  },
  async getEntityHistory({ commit }, { platformClient, entityId }) {
    commit('editHistory', '');
    let response = await platformClient.get(`https://trails.platform.cimpress.io/${applicationName}/v1/actions?entity=${entityId}&limit=100`);
    commit('editHistory', response.data);
  }
};

// mutations
const mutations = {
  editHistory(state, editHistory) {
    state.editHistory = editHistory;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
