import Vue from 'vue';
import Vuex from 'vuex';

import nala from './modules/nala';
import users from './modules/users';
import tables from './modules/tables';
import tags from './modules/tags';
import schemas from './modules/schemas';
import pdwPii from './modules/pdwPii';
import trails from './modules/trails';
import search from './modules/search';
import river from './modules/river';
import bookmark from './modules/bookmark';
import customizr from './modules/customizr';
import lookerGrapher from './modules/lookerGrapher';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    nala,
    users,
    tables,
    schemas,
    tags,
    pdwPii,
    trails,
    river,
    search,
    bookmark,
    customizr,
    lookerGrapher
  },
  strict: true
});
