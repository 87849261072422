import { updateField } from 'vuex-map-fields';
import { userAccessibleCluster } from '../../utils/userAccessibility';
// import axios from 'axios';

// initial state
const initialState = {

  selectedTreeItem: null,

  searchInput: {
    searchText: null,
    filters: {}
  },
  searching: false,
  searchResult: null,
  searchError: null,

  currentPage: 0,

  loadingBrowseData: false,
  browseData: [],
  browseDataError: null
};

// actions
const actions = {
  setSelectedTreeItem({ commit }, { selectedTreeItem }) {
    commit('selectedTreeItem', selectedTreeItem);
  },
  async getSearchResults({ commit }, { platformClient, searchValue, filters, pageNumber }) {
    commit('searchResult', []);
    commit('searchError', null);
    commit('searching', true);

    const postData = {
      search_request: {
        type: 'AND',
        filters: filters || {},
        fields: [
          'key',
          'cluster',
          'database',
          'schema',
          'name',
          'description',
          'column_names',
          'owner',
          'tags',
          'is_view'
        ]
      },
      query_term: searchValue || '',
      page_index: pageNumber || 0
    };
    try {
      const response = await platformClient.post(`${process.env.VUE_APP_AMUNDSEN_SEARCH_URL}/search_table`, postData);
      commit('searchResult', response.data); // contains searchResult based on page number of search results
    } catch (e) {
      commit('searchError', e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unexpected error occurred');
    }
    commit('searching', false);
  },

  async getBrowseData({ commit }, { platformClient, email, userAccountId }) {
    // This function returns all table details which are then used to build hierarchy tree
    commit('browseData', []);
    commit('browseDataError', null);
    commit('loadingBrowseData', true);

    try {
      let userClusters = await userAccessibleCluster(platformClient, email, userAccountId);
      let result = userClusters.map(async key => {
        const obj = {};
        let postData = {
          filters: {
            cluster: key
          },
          aggs: {
            aggregation_field: {
              composite: {
                sources: [
                  {
                    aggregation_field_source: {
                      terms: {
                        field: 'database.raw'
                      }
                    }
                  }
                ]
              },
              aggs: {
                aggregation_field: {
                  terms: {
                    field: 'schema.raw'
                  },
                  aggs: {
                    aggregation_field: {
                      terms: {
                        field: 'name.raw'
                      }
                    }
                  }
                }
              }
            }
          }
        };
        let tableDetails = await platformClient.post(
          `${process.env.VUE_APP_AMUNDSEN_SEARCH_URL}/get_aggregate_data`,
          postData
        );
        obj[key] = tableDetails.data.results;
        return obj;
      });
      result = await Promise.all(result);
      commit('browseData', { results: result.flat(2) });
    } catch (e) {
      commit('browseDataError', e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unexpected error occurred');
    }
    commit('loadingBrowseData', false);
  }
};

// mutations
const mutations = {
  updateField,
  searchInput(state, searchInput) {
    state.searchInput = searchInput;
  },
  currentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
  searching(state, searching) {
    state.searching = searching;
  },
  searchResult(state, searchResult) {
    state.searchResult = searchResult;
  },
  searchError(state, searchError) {
    state.searchError = searchError;
  },
  browseData(state, browseData) {
    state.browseData = browseData;
  },
  browseDataError(state, browseDataError) {
    state.browseDataError = browseDataError;
  },
  loadingBrowseData(state, loadingBrowseData) {
    state.loadingBrowseData = loadingBrowseData;
  },
  selectedTreeItem(state, selectedTreeItem) {
    state.selectedTreeItem = selectedTreeItem;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
