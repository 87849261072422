
const initialState = {
  loadingTags: false,
  tagsError: null,
  allTags: []
};

const actions = {
  async getAllTags({ commit }, { platformClient }) {
    commit('loadingTags', true);
    commit('tagsError', null);
    commit('allTags', []);
    try {
      let response = await platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/tags/`);
      commit('allTags', response.data);
    } catch (e) {
      const errorMessage
        = e.message
        || e.data.message
        || e.data.title
        || (e.status && `Error occurred with status code ${e.status}`)
        || 'Unknown error occurred';
      commit('tagsError', errorMessage);
    }
    commit('loadingTags', false);
  }
};

const mutations = {
  allTags(state, allTags) {
    state.allTags = allTags;
  },
  loadingTags(state, loadingTags) {
    state.loadingTags = loadingTags;
  },
  tagsError(state, tagsError) {
    state.tagsError = tagsError;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
