import { updateField } from 'vuex-map-fields';
import { isObjectDeleted } from '../../utils/table';

// initial state
const initialState = {
  matchingUsers: [],
  updatingSteward: false,
  errorUpdatingSteward: null,
  myTables: []
};

// actions
const actions = {
  async getMatchingUsers({ commit }, { platformClient, userEmail }) {
    commit('matchingUsers', []);
    const response = await platformClient.get(`https://api.cimpress.io/auth/access-management/v1/search/canonicalPrincipals/bySubstring?q=${userEmail.toLowerCase()}`);
    commit('matchingUsers', response.data.canonical_principals.map(user => user.canonical_principal.toLowerCase()));
  },
  async updateSteward({ commit }, { platformClient, stewardToUpdate, type, keyValue }) {
    commit('updatingSteward', true);
    commit('errorUpdatingSteward', null);
    try {
      await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${stewardToUpdate.toLowerCase()}/own/${type}/${keyValue}`);
    } catch (e) {
      const errorMessage
        = e.message
        || e.data.message
        || e.data.title
        || (e.status && `Error occurred with status code ${e.status}`)
        || 'Unknown error occurred';
      commit('errorUpdatingSteward', errorMessage);
    }
    commit('updatingSteward', false);
  },
  async deleteSteward({ commit }, { platformClient, stewardToDelete, keyValue, type }) {
    commit('updatingSteward', true);
    commit('errorUpdatingSteward', null);
    try {
      await platformClient.delete(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${stewardToDelete.toLowerCase()}/own/${type}/${keyValue}`);
    } catch (e) {
      const errorMessage
        = e.message
        || e.data.message
        || e.data.title
        || (e.status && `Error occurred with status code ${e.status}`)
        || 'Unknown error occurred';
      commit('errorUpdatingSteward', errorMessage);
    }
    commit('updatingSteward', false);
  },
  async getOwnedTables({ commit }, { platformClient, userEmail }) {
    commit('myTables', '');
    let response = await platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${userEmail.toLowerCase()}/own/`);
    commit('myTables', response.data);
  }
};

// mutations
const mutations = {
  updateField,
  updatingSteward(state, updatingSteward) {
    state.updatingSteward = updatingSteward;
  },
  errorUpdatingSteward(state, errorUpdatingSteward) {
    state.errorUpdatingSteward = errorUpdatingSteward;
  },
  matchingUsers(state, matchingUsers) {
    state.matchingUsers = matchingUsers;
  },
  myTables(state, myTables) {
    if (myTables) {
      myTables.table = myTables.table.filter(t => !isObjectDeleted(t));
    }
    state.myTables = myTables;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
