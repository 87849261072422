export const adaptAuthProfile = authWrapperProfile => {
  if (authWrapperProfile) {
    return {
      user_id: authWrapperProfile?.sub,
      name: authWrapperProfile?.name,
      picture: authWrapperProfile?.picture,
      app_metadata: {
        canonical_id: authWrapperProfile?.['https://claims.cimpress.io/canonical_id'],
        account: {
          id: authWrapperProfile?.['https://claims.cimpress.io/account']
        }
      }
    };
  }
  return null;
};

export default class AuthManager {
  constructor(userManager, authWrapper, logger) {
    this.userManager = userManager;
    this.authWrapper = authWrapper;
    this.logger = logger;
  }

  async login() {
    if (this.authWrapper.isLoggedIn()) {
      this.userManager.setUserData(adaptAuthProfile(this.authWrapper.getProfile()));
      this.userManager.setToken(this.getAccessToken());
      return;
    }
    let hashLocation = window.location.href.indexOf('#');
    let origin = hashLocation !== -1 ? window.location.href.substring(0, hashLocation) : window.location.href;
    const nextUri = `${origin}#/`;
    try {
      let result = await this.authWrapper.ensureAuthentication({ nextUri });
      if (result === true) {
        this.userManager.setUserData(adaptAuthProfile(this.authWrapper.getProfile()));
        this.userManager.setToken(this.getAccessToken());
      }
    } catch (error) {
      this.logger.error({ title: 'User login error', exception: error });
    }
  }

  logout() {
    this.userManager.clearUserData();
    return this.authWrapper.logout('/');
  }

  /**
   * @description Returns valid access token after user was logged in. If user is not logged in or access token is not valid, returns null.
   * @return {null|String} accessToken or null
   */
  getAccessToken() {
    return this.authWrapper.getAccessToken();
  }
}
