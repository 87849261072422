import { updateField } from 'vuex-map-fields';
// import axios from 'axios';

// initial state
const initialState = {
  userDetails: '',
  defaultCustomizrValue: {
    recentTables: [],
    visitCount: 0
  },
  visited: false
};

// actions
const actions = {
  // resource Id for Customizr: DataDiscoveryUserDetails
  async getUserDetails({ commit, state }, { platformClient }) {
    commit('userDetails', '');
    try {
      let response = await platformClient.get('https://customizr.at.cimpress.io/v1/resources/DataDiscoveryUserDetails/settings');
      commit('userDetails', response.data);
    } catch (e) {
      if (e.status === 404) {
        commit('userDetails', state.defaultCustomizrValue);
      }
    }
  },
  async putUserDetails({ commit }, { platformClient, userDetailsObject }) {
    let response = await platformClient.put('https://customizr.at.cimpress.io/v1/resources/DataDiscoveryUserDetails/settings', { recentTables: userDetailsObject.recentTables, visitCount: userDetailsObject.visitCount });
    commit('userDetails', response.data);
  },
  updateUserRecentTables({ commit }, { userData }) {
    commit('updateRecentTables', { userData });
  },
  updateUserVisitCount({ commit }) {
    commit('updateVisitCount');
  },
  visitedTour({ commit }) {
    commit('updateVisited');
  }
};

// mutations
const mutations = {
  updateField,
  userDetails(state, userDetails) {
    state.userDetails = userDetails;
  },
  updateVisited(state) {
    state.visited = true;
  },
  updateRecentTables(state, userData) {
    let keys = state.userDetails.recentTables.map(a => a.key);

    if (keys.indexOf(userData.userData.key) > -1) {
      state.userDetails.recentTables.splice(keys.indexOf(userData.userData.key), 1);
    }
    if (state.userDetails.recentTables.length >= 10) {
      state.userDetails.recentTables.shift();
    }
    state.userDetails.recentTables.push(userData.userData);
  },
  updateVisitCount(state) {
    state.userDetails.visitCount += 1;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
