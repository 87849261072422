import { updateField } from 'vuex-map-fields';
import { isObjectDeleted } from '../../utils/table';

// initial state
const initialState = {
  loadingSchemaInfo: false,
  schemaInfoError: false,
  schemaInfo: null,
  customFields: {}
};

// actions
const actions = {
  async getSchemaInfo({ commit }, { platformClient, keyValue }) {
    commit('loadingSchemaInfo', true);
    commit('schemaInfoError', null);
    commit('schemaInfo', null);

    try {
      const response = await platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/schema/${keyValue}`);
      if (isObjectDeleted(response.data)) {
        commit('schemaInfoError', `schema_uri ${keyValue} does not exist`);
      } else {
        commit('schemaInfo', response.data);
      }
    } catch (err) {
      console.log(err);
      const errorFetchingSchemaInfo = err.message || err.data.message || err.data.title || (err.status && `Error occurred with status code ${err.status}`) || "Error while loading the page. Either the schema doesn't exist or there was an error fetching schema details. Try refreshing or contact <a href='mailto:PandaSquad@cimpress.com'>PandaSquad@cimpress.com</a>";
      commit('schemaInfoError', errorFetchingSchemaInfo);
    }

    commit('loadingSchemaInfo', false);
  },
  async putDescriptionn({ commit, state }, { platformClient, keyValue, description }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/schema/${keyValue}/description`, { description: description });
    commit('schemaInfo', Object.assign({}, state.schemaInfo, { description }));
  },
  async putSlackChannel({ commit, state }, { platformClient, keyValue, slackChannel }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/schema/${keyValue}/programmatic_descriptions`, { slack_channel: slackChannel });
    const programmatic_descriptions = [...state.schemaInfo.programmatic_descriptions].filter(p => p.source !== 'slack_channel');
    const newSlackChannels = slackChannel.map(s => s.charAt(0) === '#' ? s : `#${s}`);
    programmatic_descriptions.push({ source: 'slack_channel', text: JSON.stringify(newSlackChannels) });
    commit('schemaInfo', Object.assign({}, state.schemaInfo, { programmatic_descriptions }));
  },
  async putSquadEmail({ commit, state }, { platformClient, keyValue, squadEmail }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/schema/${keyValue}/programmatic_descriptions`, { squad_email: squadEmail });
    const programmatic_descriptions = [...state.schemaInfo.programmatic_descriptions].filter(p => p.source !== 'squad_email');
    programmatic_descriptions.push({ source: 'squad_email', text: JSON.stringify(squadEmail) });
    commit('schemaInfo', Object.assign({}, state.schemaInfo, { programmatic_descriptions }));
  }
};

// mutations
const mutations = {
  updateField,
  schemaInfo(state, schemaInfo) {
    state.schemaInfo = schemaInfo;
    if (schemaInfo) {
      schemaInfo.programmatic_descriptions.forEach(function(data) {
        if (`${data.source}` === 'slack_channel' || `${data.source}` === 'squad_email') {
          const programmatic_description_field = JSON.parse(`${data.text}`.replace(/'/g, '"'));
          state.customFields[`${data.source}`] = programmatic_description_field || [];
        }
      });
    }
  },
  loadingSchemaInfo(state, loadingSchemaInfo) {
    state.loadingSchemaInfo = loadingSchemaInfo;
  },
  schemaInfoError(state, schemaInfoError) {
    state.schemaInfoError = schemaInfoError;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
