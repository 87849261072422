export default class UserManager {
  constructor() {
    this.userData = {};
    this.token = '';
  }

  getUserData() {
    return this.userData;
  }

  setUserData(data) {
    this.userData = data;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  clearUserData() {
    this.userData = {};
  }
}
