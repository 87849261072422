import { updateField } from 'vuex-map-fields';
import { isObjectDeleted, stringIsArray } from '../../utils/table';
// import axios from 'axios';
// initial state
const initialState = {
  loadingTableInfo: false,
  loadingTableStatus: false,
  tableInfoError: false,
  tableInfo: '',
  customFields: {},
  isObjectReadOnly: false,
  missingStabilityCriteria: []
};

// actions
const actions = {
  async getTableInfo({ commit }, { platformClient, keyValue }) {
    commit('loadingTableInfo', true);
    commit('tableInfoError', null);
    commit('tableInfo', null);

    try {
      const response = await platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}`);
      if (isObjectDeleted(response.data)) {
        commit('tableInfoError', `table_uri ${this.tableKey} does not exist`);
      } else {
        commit('tableInfo', response.data);
      }
    } catch (err) {
      console.log(err);
      const errorFetchingTableInfo = err.message || err.data.message || err.data.title || (err.status && `Error occurred with status code ${err.status}`) || "Error while loading the page. Either the table doesn't exist or there was an error fetching table details. Try refreshing or contact <a href='mailto:PandaSquad@cimpress.com'>PandaSquad@cimpress.com</a>";
      commit('tableInfoError', errorFetchingTableInfo);
    }

    commit('loadingTableInfo', false);
  },
  async putTableDescription({ commit, state }, { platformClient, keyValue, description }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/description`, { description: description });
    commit('tableInfo', Object.assign({}, state.tableInfo, { description }));
  },
  async putColumnDescription({ commit, state }, { platformClient, keyValue, columnName, description }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/column/${columnName}/description`, { description });
    const columns = [...state.tableInfo.columns]
      .map(c => c.name === columnName ? Object.assign({}, c, { description }) : c);

    commit('tableInfo', Object.assign({}, state.tableInfo, { columns }));
  },
  async putTablePiiColumns({ dispatch }, { platformClient, keyValue, piiColumns }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { pii_columns: piiColumns });
    await dispatch('getTableInfo', { platformClient, keyValue });
  },
  async putSlackChannel({ commit, state }, { platformClient, keyValue, slackChannel }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { slack_channel: slackChannel });
    const programmatic_descriptions = [...state.tableInfo.programmatic_descriptions].filter(p => p.source !== 'slack_channel');
    const newSlackChannels = slackChannel.map(s => s.charAt(0) === '#' ? s : `#${s}`);
    programmatic_descriptions.push({ source: 'slack_channel', text: JSON.stringify(newSlackChannels) });
    commit('tableInfo', Object.assign({}, state.tableInfo, { programmatic_descriptions }));
  },
  async putSquadEmail({ commit, state }, { platformClient, keyValue, squadEmail }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { squad_email: squadEmail });
    const programmatic_descriptions = [...state.tableInfo.programmatic_descriptions].filter(p => p.source !== 'squad_email');
    programmatic_descriptions.push({ source: 'squad_email', text: JSON.stringify(squadEmail) });
    commit('tableInfo', Object.assign({}, state.tableInfo, { programmatic_descriptions }));
  },
  async putTableStatus({ commit, state }, { platformClient, keyValue, tableStatus }) {
    commit('loadingTableStatus', true);

    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { table_status: tableStatus });
    const programmatic_descriptions = [...state.tableInfo.programmatic_descriptions].filter(p => p.source !== 'table_status');
    programmatic_descriptions.push({ source: 'table_status', text: tableStatus });

    commit('tableInfo', Object.assign({}, state.tableInfo, { programmatic_descriptions }));
    commit('loadingTableStatus', false);
  },
  async putTablePrimaryKey({ commit, state }, { platformClient, keyValue, primaryKey }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { primary_key: primaryKey });
    const programmatic_descriptions = [...state.tableInfo.programmatic_descriptions].filter(p => p.source !== 'primary_key');
    programmatic_descriptions.push({ source: 'primary_key', text: JSON.stringify(primaryKey) });
    commit('tableInfo', Object.assign({}, state.tableInfo, { programmatic_descriptions }));
  },
  async putTablePromiseOfFreshness({ commit, state }, { platformClient, keyValue, pofDuration, pofDurationFilter, pofReferenceColumn }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/table/${keyValue}/programmatic_descriptions`, { pof_duration: pofDuration, pof_duration_filter: pofDurationFilter, pof_reference_column: pofReferenceColumn });
    const programmatic_descriptions = [...state.tableInfo.programmatic_descriptions]
      .filter(p => !['pof_duration', 'pof_duration_filter', 'pof_reference_column'].includes(p.source))
      .concat([
        { source: 'pof_duration', text: pofDuration },
        { source: 'pof_duration_filter', text: pofDurationFilter },
        { source: 'pof_reference_column', text: pofReferenceColumn }
      ]);
    commit('tableInfo', Object.assign({}, state.tableInfo, { programmatic_descriptions }));
  }
};

// mutations
const mutations = {
  updateField,
  tableInfo(state, tableInfo) {
    state.isObjectReadOnly = false;
    state.tableInfo = tableInfo;
    if (tableInfo) {
      tableInfo.programmatic_descriptions.forEach(function(data) {
        if (['pii_columns', 'slack_channel', 'squad_email'].includes(data.source)) {
          let programmatic_description_field = JSON.parse(`${data.text}`.replace(/'/g, '"'));
          state.customFields[`${data.source}`] = programmatic_description_field || [];
        } else if (`${data.source}` === 'pof_duration') {
          state.customFields[`${data.source}`] = JSON.parse(`${data.text}`);
        } else if (`${data.source}` === 'pof_duration_filter') {
          state.customFields[`${data.source}`] = data.text;
        } else if (`${data.source}` === 'pof_reference_column') {
          state.customFields[`${data.source}`] = data.text;
        } else if (`${data.source}` === 'table_status') {
          state.customFields[`${data.source}`] = data.text || 'not_set'; // Default value of table status
        } else if (`${data.source}` === 'primary_key') {
          if (stringIsArray(data.text) || data.text === null) {
            let programmatic_description_field = JSON.parse(`${data.text}`.replace(/'/g, '"'));
            state.customFields[`${data.source}`] = programmatic_description_field || [];
          } else {
            state.customFields[`${data.source}`] = [data.text];
          }
        } else {
          state.customFields[`${data.source}`] = `${data.text}`;
        }
      });
      if (state.customFields.producer_object_key && !(state.customFields.producer_object_key.split('/')[2].split('.')[0].toUpperCase() === state.tableInfo.cluster.replace('.eu-west-1', '').toUpperCase())) {
        state.isObjectReadOnly = true; // Hide Edit buttons at the Consumer side of shared views
      }

      // Stability criteria validation
      state.missingStabilityCriteria = [];
      if (!state.tableInfo.description?.trim()) {
        state.missingStabilityCriteria.push('Dataset Description');
      }
      if (!state.customFields.pof_duration) {
        state.missingStabilityCriteria.push('Promise of Freshness');
      }
      if (!state.customFields.squad_email.length) {
        state.missingStabilityCriteria.push('Data Owners');
      }
      if (!state.tableInfo.owners.length) {
        state.missingStabilityCriteria.push('Stewards');
      }
      if (!state.customFields.slack_channel.length) {
        state.missingStabilityCriteria.push('Slack Channel');
      }
      if (!state.customFields.primary_key || state.customFields.primary_key.length === 0) {
        // A snowflake PK is counted as PK too
        const snowflakePks = new Set(
          state.tableInfo.columns
            .filter(c => (c.badges || []).find(p => p.badge_name === 'pk'))
            .map(c => c.name.toUpperCase())
        );
        if (snowflakePks.size === 0) {
          state.missingStabilityCriteria.push('Primary Key');
        }
      }
    }
  },
  loadingTableInfo(state, loadingTableInfo) {
    state.loadingTableInfo = loadingTableInfo;
  },
  loadingTableStatus(state, loadingTableStatus) {
    state.loadingTableStatus = loadingTableStatus;
  },
  tableInfoError(state, tableInfoError) {
    state.tableInfoError = tableInfoError;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
