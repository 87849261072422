function getEnvironment() {
  if (!window || !window.location) {
    return 'development';
  }
  if (window.location.hostname === 'data-discovery.cimpress.io') {
    return 'production';
  }
  if (window.location.hostname === 'tst.data-discovery.cimpress.io') {
    return window.location.pathname.includes('/PR') ? 'pull-request' : 'test';
  }
  return 'development';
}

const EnvironmentProviderPlugin = {
  install(Vue) {
    Vue.prototype.$env = Vue.$env = getEnvironment();
    // Test branches have base URL like https://tst.data-discovery.cimpress.io/PR-sandesh-newtab/index.html/#/
    // Normal variables will not understand this and will set base URL as 'https://tst.data-discovery.cimpress.io'
    // But the actual base URL here is 'https://tst.data-discovery.cimpress.io/PR-sandesh-newtab/index.html'
    Vue.prototype.$baseUrl = Vue.$baseUrl = window.location.href.split('#/')[0]; // Extracting base URL from the full URL.
  }
};

const Environment = {
  getEnvironment: getEnvironment
};

export { EnvironmentProviderPlugin, Environment };
