import { updateField } from 'vuex-map-fields';
// initial state
const initialState = {
  topUsers: null,

  loadingShareConsumers: false,
  shareConsumers: null,
  errorShareConsumers: false,
  errorLoadingTopUsers: false
};

// Filters table Name Special Character
function processTableName(table_name) {
  return encodeURIComponent(table_name);
  // TODO return table_name.replace(/((?<!\^)[\._"])/g, '^\$1'); // eslint-disable-line no-useless-escape
}
// actions
const actions = {
  initialiseVariablesToNull({ commit }) {
    commit('topUsers', '');
  },
  async frequentUsers({ commit }, { platformClient, account, database, schema, table_name }) {
    commit('topUsers', '');
    commit('errorLoadingTopUsers', false);
    try {
      let result = await platformClient.post('https://nala.cimpress.io/v0/looker/run', {
        configurationId: 'e114fb92-c3bc-4246-8a8f-8da3cf308cc8',
        cosmeticFilters: [
          {
            viewName: 'frequent_users',
            dimName: 'object_name',
            filterValue: `${database.toUpperCase()}.${schema.toUpperCase()}.${table_name.toUpperCase()}`
          }
        ]
      }
      );
      commit('topUsers', result.data.lookOutput);
      commit('errorLoadingTopUsers', false);
    } catch (error) {
      commit('errorLoadingTopUsers', true);
    }
  },
  async shareConsumers({ commit }, { platformClient, account, database, schema, table_name }) {
    commit('loadingShareConsumers', true);
    commit('shareConsumers', '');
    commit('errorShareConsumers', false);
    try {
      let result = await platformClient.post('https://nala.cimpress.io/v0/looker/run', {
        configurationId: '56ce2be8-2d52-4bc2-a063-ce572eeb8cf9',
        cosmeticFilters: [
          {
            viewName: 'shares_info',
            dimName: 'account_name',
            filterValue: account.toUpperCase()
          },
          {
            viewName: 'shares_info',
            dimName: 'is_deleted',
            filterValue: 'false'
          },
          {
            viewName: 'shares_info',
            dimName: 'object_database',
            filterValue: database
          },
          {
            viewName: 'shares_info',
            dimName: 'object_schema',
            filterValue: schema
          },
          {
            viewName: 'shares_info',
            dimName: 'object_name',
            filterValue: processTableName(table_name).toUpperCase()
          }
        ]
      }
      );
      commit('shareConsumers', result.data.lookOutput);
    } catch (error) {
      console.error(error);
      commit('errorShareConsumers', true);
    }
    commit('loadingShareConsumers', false);
  }
};

// mutations
const mutations = {
  updateField,
  loadingShareConsumers(state, loadingShareConsumers) {
    state.loadingShareConsumers = loadingShareConsumers;
  },
  topUsers(state, topUsers) {
    state.topUsers = topUsers;
  },
  shareConsumers(state, shareConsumers) {
    state.shareConsumers = shareConsumers;
  },
  errorLoadingTopUsers(state, errorLoadingTopUsers) {
    state.errorLoadingTopUsers = errorLoadingTopUsers;
  },
  errorShareConsumers(state, errorShareConsumers) {
    state.errorShareConsumers = errorShareConsumers;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
