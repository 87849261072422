
// initial state
const initialState = {
  piiData: false
};

// actions
const actions = {
  async getPii({ commit }, { platformClient, account, database, schema, object }) {
    commit('piiData', '');
    let response = await platformClient.get(`https://pii.pdw.cimpress.io/v0/accounts/${account}/databases/${database}/schemas/${schema}/objects/${object}`);
    commit('piiData', response.data);
  },
  async putPii({ commit }, { platformClient, account, database, schema, object, tablePiiData }) {
    let response = await platformClient.put(`https://pii.pdw.cimpress.io/v0/accounts/${account}/databases/${database}/schemas/${schema}/objects/${object}`, tablePiiData);
    commit('piiData', response.data);
  },
  async patchPii({ commit }, { platformClient, account, database, schema, object, updatePiiData }) {
    let response = await platformClient.patch(`https://pii.pdw.cimpress.io/v0/accounts/${account}/databases/${database}/schemas/${schema}/objects/${object}`, updatePiiData);
    commit('piiData', response.data);
  },
  async putScannedPiiFields({ commit }, { platformClient, account, database, schema, object, scannedPiiFieldsData }) {
    let response = await platformClient.put(`https://pii.pdw.cimpress.io/v0/accounts/${account}/databases/${database}/schemas/${schema}/objects/${object}/scannedPiiFields`, scannedPiiFieldsData);
    commit('piiData', response.data);
  }
};

// mutations
const mutations = {
  piiData(state, piiData) {
    state.piiData = piiData;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
