<template>
  <div v-if="!visited && isActive && this.$route.path === '/' && userDetails.visitCount<3" class="hidden-sm hidden-xs" style="align: center; margin: auto;">
    <div class="modal-dialog foobar" style="z-index: 1050;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" style="color: rgb(240, 85, 58);">
            Data Discovery Intro
          </h4>
        </div>
        <div class="modal-body" style="padding-top: 0px;text-align:justify;">
          <hr>
          <!-- eslint-disable -->
          <p v-if="steps[currentStep].header" v-html="steps[currentStep].header.title" />
          <p v-html="steps[currentStep].content" />
          <!-- eslint-enable -->
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="finishTour()">
            <i class="fa fa-ban" /> Skip
          </button>
          <button class="btn" :class="currentStep==0 ? 'disabled':'btn-primary'" @click="clicked(-1)">
            <i class="fa fa-chevron-left" /> Prev
          </button>
          <button v-if="currentStep!=steps.length-1" class="btn btn-primary" @click="clicked(1)">
            Next <i class="fa fa-chevron-right" />
          </button>
          <button v-else class="btn btn-primary" @click="finishTour()">
            <i class="fa fa-check" /> Finish
          </button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade in" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Tour',
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      focus: '',
      currentStep: 0,
      isActive: true
    };
  },
  computed: {
    ...mapState({
      userDetails: state => state.customizr.userDetails,
      visited: state => state.customizr.visited
    })
  },
  watch: {
    $route() {
      // if we are not on home page then tour should not start
      if (!this.visited && this.$route.path !== '/') {
        this.focus = document.querySelector(this.steps[this.currentStep].target);
        this.focus.classList.remove('inFocus');
      }
      // if we are on home page and tour is supposed to be active then tour should start
      if (!this.visited && this.isActive && this.userDetails.visitCount < 3 && this.$route.path === '/') {
        this.focus = document.querySelector(this.steps[this.currentStep].target);
        this.focus.classList.add('inFocus');
      }
    },
    userDetails() {
      if (this.userDetails.visitCount >= 3) {
        this.focus.classList.remove('inFocus');
      }
    }
  },
  methods: {
    ...mapActions({
      visitedTour: 'customizr/visitedTour'
    }),
    callOnCreate(value) {
      // focus on first element of tour called from parent component
      this.focus = document.querySelector(value);
      this.focus.classList.add('inFocus');
    },
    clicked(value) {
      // value indicates the direction. -1 to go back, +1 to go forward
      // when Next or Previous is clicked, we should remove focus on current element
      this.focus.classList.remove('inFocus');
      // if value = -1 then previous element is marked to be active
      if (value < 0 && this.currentStep > 0) {
        this.currentStep = this.currentStep + value;
      }
      // if value = 1 then next element is marked to be active
      if (value > 0 && this.currentStep < this.steps.length - 1) {
        this.currentStep = this.currentStep + value;
      }
      // add focus on the marked element accordingly
      this.focus = document.querySelector(this.steps[this.currentStep].target);
      this.focus.classList.add('inFocus');
    },
    finishTour() {
      // this is activated when Skip or Finish is clicked
      this.visitedTour();
      this.focus.classList.remove('inFocus');
      this.isActive = false;
    }
  }
};
</script>
<style scoped>
.modal-content{
height: 35vh;
position: fixed;
width: 30%;
left: 0;
bottom: 2vh;
}
</style>
