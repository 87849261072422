import { updateField } from 'vuex-map-fields';
import { isObjectDeleted } from '../../utils/table';

// import axios from 'axios';

// initial state
const initialState = {
  bookmarks: {}
};

// actions
const actions = {
  async getAllBookmarks({ commit }, { platformClient, userEmail }) {
    commit('bookmarks', '');
    let response = await platformClient.get(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${userEmail.toLowerCase()}/follow/`);
    commit('bookmarks', response.data);
  },
  async updateBookmarks({ dispatch }, { platformClient, userEmail, type, keyValue }) {
    await platformClient.put(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${userEmail.toLowerCase()}/follow/${type}/${keyValue}`);
    await dispatch('getAllBookmarks', { platformClient, userEmail });
  },
  async removeBookmark({ dispatch }, { platformClient, userEmail, keyValue, type }) {
    await platformClient.delete(`${process.env.VUE_APP_AMUNDSEN_META_URL}/user/${userEmail.toLowerCase()}/follow/${type}/${keyValue}`);
    await dispatch('getAllBookmarks', { platformClient, userEmail });
  }
};

// mutations
const mutations = {
  updateField,
  bookmarks(state, bookmarks) {
    if (bookmarks) {
      let filteredResults = bookmarks.table.filter(result => {
        return !isObjectDeleted(result);
      });
      bookmarks.table = filteredResults;
    }
    state.bookmarks = bookmarks;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
