
export function isObjectDeleted(objectInfo) {
  let lastIngestionTimestampUTC = `${objectInfo.last_ingestion_timestamp.slice(0, -3).replace(' ', 'T')}${'Z'}`;
  let currentDate = new Date();

  // If timedifference b/w object's last_ingestion_timestamp & current_timestamp is more than 48 hours, then object will be hidden from data_discovery.
  if (Math.abs(Date.parse(lastIngestionTimestampUTC) - Date.parse(currentDate.toISOString())) > 172800000) {
    return true;
  }
  return false;
}

/**
 * Tests wether the supplied string is a String representation of an array or not.
 * Mainly needed for backwards compatibility for the primary_key field.
 * @param str - String to be tested
 * @returns - True if str is a String representation of an array
 */
export function stringIsArray(str) {
  if (str === null) {
    return false;
  }
  let remove = [
    /\s+/g,
    /'(\\.|[^'])*'/g,
    /"(\\.|[^"])*"/g,
    /\d+/g
  ];
  let emptyArray = /\[,*\]/g;
  let testStr = str;
  for (let r of remove) {
    testStr = testStr.replace(r, '');
  }

  if (testStr[0] !== '[') {
    return false;
  }

  while (testStr.match(emptyArray)) {
    testStr = testStr.replace(emptyArray, '');
  }

  return testStr.length === 0;
}
