import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About')
    },
    {
      path: '/table_detail/:cluster/:database/:schema/:name',
      redirect({ params }) {
        // ! Redirect to keep old links compatible.
        return {
          path: '/', query: {
            selectedTreeItem: `${params.cluster}.${params.database}.${params.schema}.${params.name}`
          }
        };
      }
    },
    {
      path: '/myBookmarks',
      name: 'myBookmarks',
      component: () => import('@/views/MyBookmarks')
    },
    {
      path: '/myDatasets',
      name: 'myDatasets',
      component: () => import('@/views/MyTables')
    },
    {
      path: '/myTables',
      redirect: '/myDatasets'
    }
  ]
});
