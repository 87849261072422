
// initial state
const initialState = {
  riverLogs: ''
};

// actions
const actions = {
  async sendLogsToRiver({ commit }, { platformClient, streamId, postData }) {
    commit('riverLogs', '');
    let response = await platformClient.post(`https://river.cimpress.io/v2/streams/${streamId}/waterdrops`, postData);
    commit('riverLogs', response.data);
  }
};

// mutations
const mutations = {
  riverLogs(state, riverLogs) {
    state.riverLogs = riverLogs;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
