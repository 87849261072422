import { updateField } from 'vuex-map-fields';
// import axios from 'axios';

// initial state
const initialState = {
  newLookerGrapherData: '',
  lookerGrapherError: '',
  cachedLookerGrapherData: ''
};

// actions
const actions = {
  async getNewLookerGrapherData({ commit }, { platformClient, account, database, schema, table_name }) {
    commit('newLookerGrapherData', '');
    commit('lookerGrapherError', '');
    try {
      let response = await platformClient.get(`https://tools.looker.cimpress.io/v0/new/graph/${database}.${schema}.${table_name}(${account})`);
      commit('newLookerGrapherData', response);
    } catch (e) {
      commit('lookerGrapherError', e);
    }
  },
  async getCachedLookerGrapherData({ commit }, { platformClient, account, database, schema, table_name }) {
    commit('cachedLookerGrapherData', '');
    commit('lookerGrapherError', '');
    try {
      let response = await platformClient.get(`https://tools.looker.cimpress.io/v0/cached/graph/${database}.${schema}.${table_name}(${account})`);
      commit('cachedLookerGrapherData', response);
    } catch (e) {
      commit('lookerGrapherError', e);
    }
  },
  clearLookerGrapherData({ commit }) {
    commit('newLookerGrapherData', '');
    commit('cachedLookerGrapherData', '');
    commit('lookerGrapherError', '');
  }
};

// mutations
const mutations = {
  updateField,
  newLookerGrapherData(state, newLookerGrapherData) {
    state.newLookerGrapherData = newLookerGrapherData;
  },
  lookerGrapherError(state, lookerGrapherError) {
    state.lookerGrapherError = lookerGrapherError;
  },
  cachedLookerGrapherData(state, cachedLookerGrapherData) {
    state.cachedLookerGrapherData = cachedLookerGrapherData;
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations
};
